import Typography from '@mui/material/Typography';
import { ThemeProvider, styled } from '@mui/material/styles';

import Link from '../link';

import themer from '../../styles/material-theme';
import styles from './styles';

import ErrorImg from './images/500-error@2x.png';

const ErrorContent = styled('div')(styles.root);
const Description = styled('div')(styles.description);

const ErrorBoundary = () => (
    <ThemeProvider theme={themer}>
        <main>
            <ErrorContent className="container">
                <Typography variant="h1" sx={styles.title}>
                    Oops!
                </Typography>

                <Description>Something went wrong on our end.</Description>

                <Description>Please try again.</Description>

                <Link className="mt-4 mb-3" to="/">
                    Back to Home
                </Link>

                <div style={styles.iconContainer}>
                    <img src={ErrorImg} alt="" style={styles.icon} />
                </div>
            </ErrorContent>
        </main>
    </ThemeProvider>
);

export default ErrorBoundary;
