import { Suspense } from 'react';

import { Gate } from './guard';
import MainLayout from '../layouts/main';

const App = () => (
    <Suspense fallback={<div />}>
        <Gate>
            <MainLayout />
        </Gate>
    </Suspense>
);

export default App;
