import { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { useLocation, Navigate, Route } from 'react-router-dom';
import Container from '@mui/material/Container';
import { styled, useTheme } from '@mui/material/styles';

import { getIsAuthenticated } from '../redux/auth';
import Header from '../components/header';
import Footer from '../components/footer';
import Tokens from '../styles/tokens';
import { SentryRoutes } from '../utils/sentry';

import { withAuthenticated, withPublic, withCarrier, withAdminOnly, withExcludeHinshawAdmin } from '../routes/guard';

const Login = lazy(() => import('../routes/login'));
const LoginSetupMFA = lazy(() => import('../routes/login/setup-mfa'));
const LoginConfirmMFA = lazy(() => import('../routes/login/confirm-mfa'));
const ForgotPassword = lazy(() => import('../routes/login/forgot-password'));
const ResetPassword = lazy(() => import('../routes/login/reset-password'));
const Error404 = lazy(() => import('../routes/error/404'));
const ErrorSentry = lazy(() => import('../routes/error/sentry'));
const CarriersList = lazy(() => import('../routes/carriers'));
const Dashboard = lazy(() => import('../routes/dashboard'));
const ResourceSearch = lazy(() => import('../routes/resources/search'));
const ResourceDetail = lazy(() => import('../routes/resources/detail'));
const ResourceCategories = lazy(() => import('../routes/categories'));
const Signup = lazy(() => import('../routes/signup'));
const Profile = lazy(() => import('../routes/profile'));
const AboutUs = lazy(() => import('../routes/about-us'));
const RiskConsultation = lazy(() => import('../routes/risk-consultation'));
const TermsAndConditions = lazy(() => import('../routes/terms-and-conditions'));
const AzureLoginRedirect = lazy(() => import('../routes/azure-login-redir'));

const withCompleteAuth = compose(withAuthenticated, withCarrier);
const withAdmin = compose(withAuthenticated, withAdminOnly);
const withAuthMinusHinshawAdmins = compose(withAuthenticated, withExcludeHinshawAdmin);

const Content = styled('div')(({ theme, isAuthenticated, hideHeader }) => ({
    // https://css-tricks.com/books/greatest-css-tricks/hard-stop-gradients/
    background: isAuthenticated
        ? `linear-gradient(
        to bottom,
        ${theme.palette.primary.main},
        ${theme.palette.primary.main} 37.5%,
        ${Tokens.colors.lightestGray} 37.5%,
        ${Tokens.colors.lightestGray})
    `
        : `linear-gradient(
        to bottom,
        ${theme.palette.primary.main},
        ${theme.palette.primary.main} 60%,
        ${Tokens.colors.secondaryBlue} 60%,
        ${Tokens.colors.secondaryBlue})
    `,
    height: `calc(100vh - ${hideHeader ? '0px' : theme.mixins.toolbar.height})`,
    overflowY: 'auto',
}));

const MainLayout = () => {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const isDev = process.env.NODE_ENV === 'development' || process.env.REACT_APP_DEPLOY_ENV === 'development';
    const location = useLocation();
    // hide header on home page, as main content covers roughly the same purpose
    const hideHeader = location.pathname === '/';
    const theme = useTheme();

    return (
        <>
            {!hideHeader && <Header />}
            <Content isAuthenticated={isAuthenticated} hideHeader={hideHeader} id="ll-global-scroll-container">
                <Container
                    component="main"
                    maxWidth="xl"
                    sx={{
                        // padding to prevent margin collapse
                        pt: 10,
                        pb: 5,
                        // Our main content should fill all available space outside the header and footer (both of which are theme.mixins.toolbar.height tall),
                        // This means, at minimum, the space remaining in the viewport's height after those toolbars.
                        // If taller, though, the content should push the footer down per the content's intrinsic height (fit-content)
                        // footer calc must be kept in sync w/ same logic in components/footer
                        minHeight: {
                            xs: `calc(100vh - ${hideHeader ? '0px' : theme.mixins.toolbar.height} - calc(${theme.mixins.footer.height} * 2))`,
                            lg: `calc(100vh - ${hideHeader ? '0px' : theme.mixins.toolbar.height} - ${theme.mixins.footer.height})`,
                        },
                        height: 'fit-content',
                    }}
                >
                    {/*
                        Calling out an unexpected bug, to explain nesting of Suspense boundary here
                        If our Header is nested within Suspense, then, on navigating by selecting a menu item,
                        the menu detaches from the toolbar and jumps to the bottom of the screen because our menu component
                        was unmounted while still showing an open state and doesn't react to the new menu instance mounting (tho
                        does close on click)
                    */}
                    <Suspense fallback={<div />}>
                        <SentryRoutes>
                            {/* Public routes */}
                            <Route path="/" Component={withPublic(Login)} />
                            <Route path="/azure-login" Component={withPublic(AzureLoginRedirect)} />
                            <Route path="/login/setup/:proofToken" Component={withPublic(LoginSetupMFA)} />
                            <Route path="/login/confirm/:proofToken" Component={withPublic(LoginConfirmMFA)} />
                            <Route path="/signup" Component={withPublic(Signup)} />
                            <Route path="/forgot-password" Component={withPublic(ForgotPassword)} />
                            <Route path="/reset-password" Component={withPublic(ResetPassword)} />
                            <Route path="/terms-and-conditions" Component={TermsAndConditions} />
                            {/* Authenticated routes */}
                            <Route path="/carriers" Component={withAdmin(CarriersList)} />
                            <Route path="/dashboard" Component={withCompleteAuth(Dashboard)} />
                            <Route path="/tip-of-the-month" Component={withCompleteAuth(ResourceSearch)} />
                            <Route path="/newsletter" Component={withCompleteAuth(ResourceSearch)} />
                            <Route path="/cles" Component={withCompleteAuth(ResourceSearch)} />
                            <Route path="/resources" Component={withCompleteAuth(ResourceSearch)} />
                            <Route path="/resources/:resourceId" Component={withCompleteAuth(ResourceDetail)} />
                            <Route path="/categories" Component={withCompleteAuth(ResourceCategories)} />
                            <Route path="/about-us" Component={withCompleteAuth(AboutUs)} />
                            <Route path="/risk-consultation" Component={withCompleteAuth(RiskConsultation)} />
                            {/* snowflake auth-guard here due to the fact that hinshaw admins are expected
                            to be registered in Hinshaw's Azure IDP; they will authenticate via SAML, user data stored
                            elsewhere, so we're blocking any application user management, both React- and WP-sides, to
                            respect that source of truth. We restrict by hinshaw admins as a proxy for "auth'd by Azure,"
                            which we don't have a way to check directly */}
                            <Route path="/profile" Component={withAuthMinusHinshawAdmins(Profile)} />
                            {/* Utility routes */}
                            {isDev && <Route path="/error/sentry-test" Component={ErrorSentry} />}
                            <Route path="/error/404" Component={Error404} />
                            <Route path="/*" element={<Navigate replace to="/error/404" />} />
                        </SentryRoutes>
                    </Suspense>
                </Container>
                <Footer />
            </Content>
        </>
    );
};

export default MainLayout;
