import { useSelector } from 'react-redux';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled, lighten } from '@mui/material/styles';

import AccountMenu from './account-menu';
import CarrierTabs from './carrier-tabs';
import Button from '../button';
import { getIsAuthenticated, getCurrentCarrier } from '../../redux/auth';
import HinshawLogo from '../../images/hinshaw.png';
import HinshawDarkLogo from '../../images/hinshaw-dark.png';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'isAuthenticated' })(
    ({ theme, isAuthenticated }) => ({
        backgroundColor: isAuthenticated ? theme.palette.background.paper : lighten(theme.palette.primary.main, 0.15),
        paddingLeft: theme.spacing(4),
    }),
);

const Logo = styled('img')(({ theme }) => ({
    width: '150px',
    maxHeight: theme.mixins.toolbar.height,
}));

const Header = () => {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const currentCarrier = useSelector(getCurrentCarrier);

    return (
        <>
            <AppBar component="nav" position="fixed" isAuthenticated={isAuthenticated}>
                <Toolbar>
                    {isAuthenticated ? (
                        <>
                            <Box sx={{ display: 'flex', flexGrow: 1 }}>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    divider={
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{ height: (theme) => theme.spacing(4), alignSelf: 'initial' }}
                                        />
                                    }
                                    spacing={2}
                                >
                                    {currentCarrier ? (
                                        <Logo src={currentCarrier.logo} alt="Carrier Logo" />
                                    ) : (
                                        <Logo src={HinshawLogo} alt="Hinshaw Logo" />
                                    )}
                                    {!!currentCarrier && (
                                        <>
                                            <Logo src={HinshawLogo} alt="Hinshaw Logo" />
                                            <Typography variant="h3" color="primary" sx={{ fontWeight: 'medium' }}>
                                                Lawyering Law
                                            </Typography>
                                        </>
                                    )}
                                </Stack>
                            </Box>
                            {currentCarrier && <CarrierTabs />}
                            <Box
                                {...(!currentCarrier && {
                                    sx: { display: 'flex', flexGrow: 1, justifyContent: 'flex-end' },
                                })}
                            >
                                <AccountMenu />
                            </Box>
                        </>
                    ) : (
                        <>
                            <Link to="/">
                                <Logo src={HinshawDarkLogo} alt="Hinshaw Logo" />
                            </Link>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexGrow: 1,
                                    justifyContent: 'flex-end',
                                    mr: 2,
                                }}
                            >
                                <Box>
                                    {' '}
                                    {/* Nesting ensures flexGrow nudges button group all the way to the right without stretching button height */}
                                    <Button to="/signup" variant="text-light" sx={{ mr: 2 }}>
                                        Create Account
                                    </Button>
                                    <Button to="/" variant="light" sx={{ p: 0 }}>
                                        Log In
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            <Offset />
        </>
    );
};

export default Header;
